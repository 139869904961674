<template>
  <div class="content herbs flex flex-col gap-2">
    <table class="table table-google herb-operations">
      <thead>
        <tr>
          <th class="text-nowrap">ID</th>
          <th class="text-nowrap">Production process</th>
          <th class="text-nowrap row-fit">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="operation in operations" :key="operation.id">
          <td class="row-fit text-right">{{ leftPadZero(operation.Id, idTargetLength) }}</td>
          <td>{{ operation.Name }}</td>
          <td>
            <div class="flex gap-2">
              <b-button
                variant="primary"
                size="sm"
                @click="renameOperation(operation)"
                :disabled="loadingCount > 0"
              >
                Rename
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  getArrayTargetLength,
  leftPadZero,
} from '@/helper';

export default {
  name: 'Operations',
  components: {},
  computed: {
    idTargetLength() {
      return getArrayTargetLength(this.operations.map((operation) => operation.Id));
    },
  },
  data() {
    return {
      loadingCount: 0,
      operations: [],
    };
  },
  methods: {
    fetchOperations() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.oper' })
        .send({ method: 'opers' })
        .then((res) => {
          this.operations = res.body;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    renameOperation(operation) {
      const operationName = prompt('Enter new name', operation.Name);
      if (!operationName) {
        return;
      }
      const params = {
        operid: operation.Id,
        name: operationName,
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.oper' })
        .send({ method: 'operName' })
        .send({ params })
        .then(() => {
          this.fetchOperations();
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    leftPadZero(value, targetLength) {
      return leftPadZero(value, targetLength);
    },
  },
  created() {
    this.fetchOperations();
  },
};
</script>

<style lang="scss" scoped>
  .herb-operations {
    max-width: 500px;
  }
</style>
